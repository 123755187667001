import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import HeroChequeEspecial from '../cheque-especial-sucesso/sections/hero/_index'

import pageContext from './pageContext.json'

const ChequeEspecialSeguranca = () => {
  const urlMobileSeguranca = 'https://bancointer.go.link/chequeEspecial?adj_t=1bevbymz&adj_adgroup=contadigital&adj_creative=onboarding_chequeespecial_seguranca&adj_fallback=https%3A%2F%2Finter.co%2Fpra-voce%2Femprestimos%2Fcheque-especial%2F%3Futm_source%3Dsite%26utm_medium%3Dsite%26utm_campaign%3Dsite_contadigital_onboarding_chequeespecial_seguranca&adj_redirect_macos=https%3A%2F%2Finter.co%2Fpra-voce%2Femprestimos%2Fcheque-especial%2F%3Futm_source%3Dsite%26utm_medium%3Dsite%26utm_campaign%3Dsite_contadigital_onboarding_chequeespecial_seguranca'

  return (
    <Layout pageContext={pageContext}>
      <HeroChequeEspecial
        title='Não foi possível processar a solicitação'
        subTitle='Para a sua segurança, realize a contratação do Cheque Especial entre <strong>6h</strong> e <strong>22h30</strong>.'
        setIcon='close'
        url={urlMobileSeguranca}
        isPage='seguranca'
      />
    </Layout>
  )
}

export default ChequeEspecialSeguranca
